
// Fonts
@import url(https://fonts.googleapis.com/css?family=Montserrat:700,400,200|Cinzel:400|Raleway:400,600);

// Variables
@import "variables";
@import "reset";
@import "buttons";
@import "typography";
@import "messages";
// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

*:focus {
    outline: none;
}
.navbar {
    background-color: #FFFFFF;
}

.navbar-nav > li > a {
    line-height: 52px;
}

.navbar-brand {
    height: 80px;

    img {
      margin-bottom: -20px;
    }
}

.form-group.required .control-label:after {
    content: "*";
    color: red;
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 22px;
    list-style: none;
    background-color: inherit;
    border-radius: inherit;
}

.breadcrumb > li {
    color: #ccc;
}

.breadcrumb > li + li:before {
    content: "\003e ";
}

.breadcrumb > .active {
    color: inherit;
    font-weight: bold;
}

.payments li {
    display: inline-block;
}

.reinsurance .glyphicon {
    font-size: 2.5em;
}

.reinsurance.row {
    margin-top: 25px;
}

.payments.row {
    margin-top: 25px;
}

.terms.row {
    margin-top: 25px;
}




.su-accordion { margin-bottom: 1.5em; }
.su-accordion .su-spoiler { margin-bottom: 0.5em; }
.su-spoiler-title {
    position: relative;
    cursor: pointer;
    min-height: 20px;
    line-height: 20px;
    padding: 7px 7px 7px 34px;
    font-weight: bold;
    font-size: 13px;
}
.su-spoiler-icon {
    position: absolute;
    left: 7px;
    top: 7px;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 21px;
    text-align: center;
    font-size: 14px;
    font-family: FontAwesome;
    font-weight: normal;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    *margin-right: .3em;
}
.su-spoiler-content {
    padding: 14px;
    -webkit-transition: padding-top .2s;
    -moz-transition: padding-top .2s;
    -o-transition: padding-top .2s;
    transition: padding-top .2s;
    -ie-transition: padding-top .2s;
}
.su-spoiler.su-spoiler-closed > .su-spoiler-content {
    height: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
    border: none;
    opacity: 0;
}
.su-spoiler-icon-plus .su-spoiler-icon:before { content: "\f068"; }
.su-spoiler-icon-plus.su-spoiler-closed .su-spoiler-icon:before { content: "\f067"; }
.su-spoiler-icon-plus-circle .su-spoiler-icon:before { content: "\f056"; }
.su-spoiler-icon-plus-circle.su-spoiler-closed .su-spoiler-icon:before { content: "\f055"; }
.su-spoiler-icon-plus-square-1 .su-spoiler-icon:before { content: "\f146"; }
.su-spoiler-icon-plus-square-1.su-spoiler-closed .su-spoiler-icon:before { content: "\f0fe"; }
.su-spoiler-icon-plus-square-2 .su-spoiler-icon:before { content: "\f117"; }
.su-spoiler-icon-plus-square-2.su-spoiler-closed .su-spoiler-icon:before { content: "\f116"; }
.su-spoiler-icon-arrow .su-spoiler-icon:before { content: "\f063"; }
.su-spoiler-icon-arrow.su-spoiler-closed .su-spoiler-icon:before { content: "\f061"; }
.su-spoiler-icon-arrow-circle-1 .su-spoiler-icon:before { content: "\f0ab"; }
.su-spoiler-icon-arrow-circle-1.su-spoiler-closed .su-spoiler-icon:before { content: "\f0a9"; }
.su-spoiler-icon-arrow-circle-2 .su-spoiler-icon:before { content: "\f01a"; }
.su-spoiler-icon-arrow-circle-2.su-spoiler-closed .su-spoiler-icon:before { content: "\f18e"; }
.su-spoiler-icon-chevron .su-spoiler-icon:before { content: "\f078"; }
.su-spoiler-icon-chevron.su-spoiler-closed .su-spoiler-icon:before { content: "\f054"; }
.su-spoiler-icon-chevron-circle .su-spoiler-icon:before { content: "\f13a"; }
.su-spoiler-icon-chevron-circle.su-spoiler-closed .su-spoiler-icon:before { content: "\f138"; }
.su-spoiler-icon-caret .su-spoiler-icon:before { content: "\f0d7"; }
.su-spoiler-icon-caret.su-spoiler-closed .su-spoiler-icon:before { content: "\f0da"; }
.su-spoiler-icon-caret-square .su-spoiler-icon:before { content: "\f150"; }
.su-spoiler-icon-caret-square.su-spoiler-closed .su-spoiler-icon:before { content: "\f152"; }
.su-spoiler-icon-folder-1 .su-spoiler-icon:before { content: "\f07c"; }
.su-spoiler-icon-folder-1.su-spoiler-closed .su-spoiler-icon:before { content: "\f07b"; }
.su-spoiler-icon-folder-2 .su-spoiler-icon:before { content: "\f115"; }
.su-spoiler-icon-folder-2.su-spoiler-closed .su-spoiler-icon:before { content: "\f114"; }
.su-spoiler-style-default { }
.su-spoiler-style-default > .su-spoiler-title {
    padding-left: 27px;
    padding-right: 0;
}
.su-spoiler-style-default > .su-spoiler-title > .su-spoiler-icon { left: 0; }
.su-spoiler-style-default > .su-spoiler-content { padding: 1em 0 1em 27px; }
.su-spoiler-style-fancy {
    border: 1px solid #ccc;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #fff;
    color: #333;
}
.su-spoiler-style-fancy > .su-spoiler-title {
    border-bottom: 1px solid #ccc;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background: #f0f0f0;
    font-size: 0.9em;
}
.su-spoiler-style-fancy.su-spoiler-closed > .su-spoiler-title { border: none; }
.su-spoiler-style-fancy > .su-spoiler-content {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
}
.su-spoiler-style-simple {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
}
.su-spoiler-style-simple > .su-spoiler-title {
    padding: 5px 10px;
    background: #f0f0f0;
    color: #333;
    font-size: 0.9em;
}
.su-spoiler-style-simple > .su-spoiler-title > .su-spoiler-icon { display: none; }
.su-spoiler-style-simple > .su-spoiler-content {
    padding: 1em 10px;
    background: #fff;
    color: #333;
}






/* Céline */
body{
    font-family: $font-family-sans-serif;
    font-size:$font-size-base;
    color:$text-color;

    &.fixed {
        overflow-y: hidden;
    }
}
.fa-angle-right {
    display:inline-block;
    margin-left:10px;
}
h1{
    font-size:2.4em;
}
h2{

}
h3{

}
h4{
    font-size:1.4em;
    margin-bottom:0.4em;
}
a{
    transition: all linear .2s;
}
td{
    i{
        display:block;
        color:$grey;
        font-style:italic;
        font-size:12px;
        margin:5px 0;
    }
}
.pf{
    font-size:50px;
    color:$gold;
}
.wrapper{
    max-width:1170px;
    width:90%;
    margin:0 auto;
}
.navbar-toggle{
    display:none;
}
fieldset{
    width:100%;
}
button{
    cursor:pointer;
}
.col-md-2{
    width:13%;
}
.col-md-3{
    width:23%;
}
.col-md-4{
    width:30%;

    &.col-12 {
      @media only screen and (max-width: 820px) {
        width: 100%;
      }
    }
}
.col-md-6{
    width:48.5%;

    &.col-12 {
      @media only screen and (max-width: 700px) {
        width: 100%;
        margin-bottom: 50px;
      }
    }
}
.col-md-7{
    width:58%;
}
.col-md-8 {
    width:67%;

    &.col-12 {
      @media only screen and (max-width: 820px) {
        width: 100%;
        margin-bottom: 50px;
      }
    }
}
.breadcrumb{
    padding:0;
    margin:0;
}
.container{
    width:100%;
    padding:0;
}

nav.navbar{
    margin-bottom:0;
    .container{
        max-width:1170px;
        width:90%;
        margin:0 auto;
        display:flex;
        align-items: center;
        justify-content: space-between;
    }
    .navbar-right{
        display:flex;
        li{
            margin-left:25px;
            list-style:none;
            .log{
                font-size:0.9em;
            }
        }
        .fa-shopping-basket{
            font-size:1.3em;
        }
        a{
            color:$text-color;
            text-decoration: none;
            &:hover,
            &:focus{
                color:$gold;
            }
        }
    }
}



.breadcrumb{
    max-width:1170px;
    width:90%;
    margin:0 auto;
    display:flex;
}
.step {
    display:flex;
    max-width:1170px;
    width:90%;
    background:lighten($grey, 15%);
    counter-reset: counter;
    margin: 20px auto 0;
    &.text-center {
      margin-top: 20px;
    }
    > li{
        width:25%;
        text-transform: uppercase;
        font-weight: normal;
        height:50px;
        display: flex;
        align-items: center;
        position: relative;
        &:before{
            display: inline-block;
            content: counter(counter);
            counter-increment: counter;
            background: rgba($white, 0.6);
            width:25px;
            height: 25px;
            line-height:25px;
            border-radius:3px;
            margin-right:10px;
            text-align: center;
            margin-left:4%;
        }
    + li:before{
        content: counter(counter);
        counter-increment: counter;
    }
    a{
        display:block;
    }
    span{
        position: absolute;
        right:0;
        top:0;
        &.hidden{
            display:none;
        }
        i{
            display:none;
        }
        &:before{
            display:block;
            content:"";
            border-top:25px solid lighten($grey, 15%);
            border-left:25px solid transparent;
        }
        &:after{
            display:block;
            content:"";
            border-bottom:25px solid lighten($grey, 15%);
            border-left:25px solid transparent;
        }
    }
    &.active{
        background: $gold;
        color:$white;
        font-weight:normal;
        &:before{
            background: rgba($white, 0.4);
            color:$white;
        }
    }
    &.completed{
        background:darken($gold, 12%);
            &:before{
                background:rgba($white,0.2);
                color:$white;
            }
            a{
                text-decoration: none;
                color:$white;
                cursor:pointer;
            }
        }
        &.before{
            background:darken($gold, 12%);
            span{
                &:before{
                    border-top-color:$gold;
                }
                &:after{
                    border-bottom-color:$gold;
                }
            }
        }
    }
}





#app{
    padding:0;
}
h1{
    background: url('../img/bg-title.jpg') center center no-repeat;
    background-size: cover;
    color:$white;
    font-family: $font-family-serif;
    text-shadow: 1px 1px 2px black;
    text-align:center;
    padding:1.2em 0;
    margin-bottom: 1.4em;
}
> .row{
    max-width:1170px;
    width:90%;
    margin:0 auto;
    display:flex;
    justify-content: space-between;
}
.content{
    max-width:1170px;
    width:90%;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    padding: calc(2% + 10px) 0 2%;
    align-items: flex-start;
    > a{
        text-decoration: none;
        margin-bottom: 50px;
        display: block;
        flex:none;
        width:100%;
        color:$text-color;
        &:hover,
        &:focus{
            color:$gold;
        }
        span{
            font-size: 18px;
            display:inline-block;
            margin-right: 7px;
        }
    }
    legend{
        font-size:1.2em;
        display: block;
        margin-bottom: 15px;
        border-bottom: 1px solid #e5e5e5;
        width:100%;
        padding-bottom:0.5em;
        text-transform: uppercase;
        text-align:left;
        a{
            display:block;
            font-size:14px;
            margin-top:10px;
            color:$text-color;
            &:hover,
            &:focus{
                color:$gold;
            }
        }
    }
    p{
        margin:1em 0;
        &.label{
            margin: 0.8em 0;
        }
        strong{
            color:$gold;
        }
        &.total{
            text-transform:uppercase;
            font-size:16px;
            text-align:right;
            padding-right: 0.8em;
            font-weight:bold;
            span{
                color: darken($text-color, 10%);
                color:$gold;

                &.vat-included {
                    text-transform: none;
                    font-size: 12px;
                    display: block;
                    margin-top: 5px;
                }
            }
        }
        &.tva{
            text-align:right;
            padding-right: 0.8em;
        }
        a{
            color:$gold;
        }
    }
    fieldset{
        > a {
            float:right;
            margin-top:30px;
            color:$white;
            text-decoration: none;
            border-radius:3px;
            display:inline-block;
            padding:1em;
            background:$gold;
            &:hover,
            &:focus{
                background:darken($gold, 5%);
            }
            &.edit{
                background:lighten($grey, 15%);
                color:$text-color;
                font-size:80%;
                &:hover,
                &:focus{
                    background:$gold;
                    color:$white;
                }
            }
        }
    }
    .table {
        width: 100%;
        max-width: 100%;
        // Cells
        > thead,
        > tbody,
        > tfoot {
            > tr {
                > th,
                > td {
                    vertical-align: top;
                    border-top: 1px solid #ddd;
                    padding:1em 0.8em;
                    text-align:left;
                }
            }
        }
        // Bottom align for column headings
        > thead > tr > th {
            vertical-align: bottom;
            border-bottom: 2px solid #ddd;
            background:lighten($grey, 15%);
        }
        // Remove top border from thead by default
        > caption + thead,
        > colgroup + thead,
        > thead:first-child {
            > tr:first-child {
                > th,
                > td {
                    border-top: 0;
                }
            }
        }
        // Account for multiple tbody instances
        > tbody + tbody {
            border-top: 2px solid #ddd;
        }
        td.last{
            text-align:right;
            a{
                color:$red;
                text-decoration: none;
            }
        }

    }
}

form{
    display:flex;
    flex-wrap:wrap;
    justify-content: space-between;
    .has-error{
        input,
        textarea{
            border-color:$red;
        }
        .help-block{
            margin-top:5px;
            color:$red;
            font-size:80%;
        }
    }
    .form-group{
        position: relative;
        span.fa-exclamation{
            color:$red;
            position: absolute;
            right:10px;
            top:35px;
        }
    }

    > .form-group{
        width: 48.5%;
        margin-bottom:10px;
        &.last{
            margin-top:30px;
            width:100%;
            display:flex;
            align-items: center;
            justify-content: space-between;
            a{
                color:$text-color;
                &:hover,
                &:focus{
                    color:$gold;
                }
            }
            button{
                margin:0;
                border:none;
                font-size:100%;
                cursor:pointer;
            }
        }
    }
    > .row{
        width:100%;
        display:flex;
        justify-content: space-between;
        margin-bottom:10px;
    }
    select{
        background:lighten($grey, 15%) url('../img/arrow-select.png') right center no-repeat;
        border:none;
        border:1px solid lighten($grey, 10%);
        width:100%;
        display: block;
        font-size:100%;
        color:$text-color;
        height:37px;
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        -webkit-appearance: none;
        -moz-appearance:    none;
        appearance:         none;
        padding-left: 10px;
    }
    select::-ms-expand {
        display: none;
    }
    label{
        display: block;
        padding:5px 0;
    }
    textarea{
        width:100%;
        background:lighten($grey, 15%);
        border:none;
        border:1px solid lighten($grey, 10%);
        padding:1em;
        width:100%;
        box-sizing: border-box;
        height:100px;
        border-radius:4px;
    }
    input{
        background:lighten($grey, 15%);
        border:none;
        border:1px solid lighten($grey, 10%);
        padding:1em;
        width:100%;
        box-sizing: border-box;
        border-radius:4px;
    }
    .radio,
    .checkbox{
        input{
            display:inline-block;
            width:auto;
        }
        i{
            font-style: italic;
            color:lighten($text-color, 20%);
        }
    }
    .checkbox{
        border-top:1px solid lighten($grey, 15%);
        margin-top:20px;
        padding-top:20px;
        width:100%;
        a{
            color:$gold;
        }
        &.deliverycheck{
            background:lighten($grey, 15%);
            padding:0.8em;
            border:none;
            margin-bottom:15px;
        }
    }
    button{
        border:none;
        font-size:100%;
        margin:0;
        cursor:pointer;
        &.pull-right{
            margin:0;
            margin-top:30px;
            align-self: flex-end;
            cursor:pointer;
        }
        &:hover,
        &:focus{
            background:darken($gold, 5%);
        }
    }
    .next-step{
        display:flex;
        justify-content: flex-end;
        width:100%;
        i{
            display:block;
            font-style:italic;
            color:darken($grey,5%);
            margin-top:10px;
        }
    }
}
#deliveryAddress{
    width:100%;
    margin-top: 15px;
    margin-left:5%;
    > .row{
        display:flex;
        justify-content: space-between;
    }
}
#subdelivery{
    > .row{
        display:flex;
        justify-content: space-between;
    }
}




footer{
    background:lighten($grey,10%);
    .reinsurance{
        max-width: 1170px;
        width:90%;
        margin:0 auto;
        padding: 1.8em 0;
        color:$text-color;
        .row{
            display:flex;
            justify-content: space-between;
            div{
                text-align:center;
                .fa{
                    font-size:3em;
                    display:inline-block;
                    margin-bottom:0.3em;
                }

            }
        }
    }
    .payments{
        background: lighten($grey, 15%);
        margin-top:0;
        padding:1.6em;
        ul{
            text-align:center;
            padding:0;
            margin:0;
            width: 100%;
            li{
                margin: 0 10px;
                img{
                    margin:0 5px;
                }
            }
        }
    }

    .address.row {
      text-align: center;
      display: block;
      padding:20px 0 0;
      font-size: 12px;
    }

    .terms.row{
        text-align:center;
        margin:0;
        padding:5px 0 20px;
        display: block;
        a{
            font-size:11px;
            text-decoration: none;
            color:$text-color;
            cursor:pointer;
            transition:all linear .2s;
            &:hover, &:focus{
                color:$gold;
            }
        }
    }
}

.wrapper{
    h2{
        font-family:$font-family-serif;
        font-size:1.7em;
    }
    .product-list{
        display:flex;
        align-items:center;
        flex-wrap:wrap;
        .item{
            width:30%;
            &:nth-child(3n+2){
                margin:5%;
            }
        }
    }
}
.login{
    max-width:700px;
    margin:0 auto;
    .next-step{
        justify-content: space-between;
        button{
            margin:0;
            cursor:pointer;
        }
        .link{
            display:flex;
            align-items: center;
            a{
                margin: 0 10px;
                color:$text-color;
                &:hover,
                &:focus{
                    color:$gold;
                }
            }
        }
    }
}
.pull-left{
    margin-top:30px;
}


#app .content .filters {
    background: $bg-box-color;
    flex: 1;
    padding: 15px 20px;
    display: flex;
    color: $black;
    border: 1px solid $border-box-color;

    h3 {
	    text-transform: uppercase;
	    display: none;
	    cursor: pointer;
	    padding: 15px;
	    margin-top: -15px;
	    margin-left: -15px;
	    margin-bottom: -15px;
	    margin-right: -15px;
    }
    &.fixed {
        position: fixed;
        top: 0;
        max-width: 1108px;
        left: 5%;
        right: 5%;

        @media only screen and (min-width: 1300px) {
            left: 50%;
            right: auto;
            transform: translateX(-50%);
            width: 1108px;
        }
    }
    form select {
        background: $white url(../img/arrow-select.png) right center no-repeat;
    }
    label {
        display: inline-block;

        &:before {
            display: inline-block;
            content:"";
            width: 10px;
            height: 10px;
            border:2px solid $black;
            margin-right: 10px;
            vertical-align: middle;
        }
    }
    input[type="submit"] {
        margin: 0;
        transition: all linear .2s;
        border-radius: 0;
        margin-top: 30px;
        cursor: pointer;
    }
    input[type="checkbox"]{
        display: none;
        width: auto;
        vertical-align: middle;

        &:checked + label:before {
            background: url('../img/checked.png') center center no-repeat;

            /* ECRAN RETINA */
            @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
            only screen and (-o-max-device-pixel-ratio: 3/2),
            only screen and (max--moz-device-pixel-ratio: 1.5),
            only screen and (max-device-pixel-ratio: 1.5) {
            	background: url('../img/checked.png') center center no-repeat;
            }
            @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
            only screen and (-o-min-device-pixel-ratio: 3/2),
            only screen and (min--moz-device-pixel-ratio: 1.5),
            only screen and (min-device-pixel-ratio: 1.5) {
                background: url('../img/checked@2x.png') center center no-repeat;
                background-size: 8px 8px;
            }
        }
    }

    form{
        flex: 0 0 30%;
        &.first {
            display: flex;
            flex: 0 0 100%;
            margin-right: 2%;
            fieldset {
                flex: 0 0 32%;
                &:nth-child(2){
                    margin: 0 2%;
                }
            }
        }
    }

    legend {
        padding-bottom: 0;
        border:none;
    }
}

#app .item-list {
    width: 100%;

    p.results {
        font-weight: 300;
        span {
            color: $gold;
            font-weight: 700;
        }
    }
    &-element {
        padding: 5%;
        display: flex;
        align-items: flex-start;

        &:nth-child(2n+1) {
            background: lighten($grey, 15%);
        }

        .item-texte {
            flex: 1;
            margin: 0 30px;
        }

        .pill {
            display: inline-block;
            padding: 5px 10px;
            border-radius: 10px;
            color: $white;
            font-size: 11px;
            line-height: 1;
            margin-top: 10px;

            &-forfait {
                background: $label-blue;
            }

            &-soin {
                background: $label-green;
            }
        }
        .col-3 {
            flex: 0 0 220px;
            text-align: center;

            .btn {
                width: 100%;
                box-sizing: border-box;
                margin-top: 20px;
            }
            div {
                p {
                    color: $gold;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                }
            }
        }

    .item-checked,
    .item-to-checked {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        h4 {
            flex: 0 0 100%;
            margin-bottom: 15px;
        }
        div {
            line-height: 1.4;
            margin-bottom: 5px;
            flex: 0 0 49%;

            a {
                display: flex;
                align-items: center;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: $text-color;
                text-decoration: none;
                font-weight: 400;
                font-size: 12px;

                &:hover,
                &:focus {
                    background: rgba(0,0,0,0.05);
                }

                img {
                    margin-right: 15px;
                }
            }
            /*

            &:before {
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            content: "\f00c";
            color: $gold;
            }
            */
            }
        }
    }
}

#app {
    .book {
        flex: 0 0 65%;
        align-items: flex-start;
        h2{
            margin-bottom: 0;
            font-family: $font-family-serif;
            font-weight: 400;
            font-size: 18px;
        }
        .alert {
	        margin-bottom: 0;
        }
        form {
            display:block;
            position: relative;

            .header {
                display: flex;
                padding: 30px;
                border: 1px solid $border-box-color;
                h3 {
                    margin-bottom: 10px;
                }
            }
            img {
                margin-right: 34px;
            }
            .item-texte {
                flex: 1;
                border: 1px solid $border-box-color;
                border-top: none;
                padding: 30px;
                background: lighten($grey, 18%);
            }

            .offers {
                margin-bottom: 30px;

                .price {
                    color: #d2af59;
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                    white-space: nowrap;
                    flex: 1;
                }
            }
            input {
                width: auto;
                &[type="submit"] {
                    border-radius: 0;
                    margin-top: 30px;
                    position: absolute;
                    right: 0;
                    cursor: pointer;
                }
            }

            .group-item {
                margin-bottom: 20px;
                select {
                    margin-bottom: 15px;
                }
                .person {
                    margin-bottom: 15px;
                    label {
                        display: block;
                        margin-right: 20px;

                        &:before {
                            display: inline-block;
                            content:"";
                            width: 10px;
                            height: 10px;
                            border:2px solid $black;
                            margin-right: 10px;
                            vertical-align: middle
                        }
                        input {
                            width: 80%;
                            background: none;
                            border:none;
                            border-bottom: 2px solid $black;
                            border-radius: 0;
                            font-size: 15px;
                            padding: 7px;
                        }
                    }
                    input[type="checkbox"]{
                        display: none;
                        width: auto;
                        vertical-align: middle;

                        &:checked + label:before {
                            background: url('../img/checked.png') center center no-repeat;

                            /* ECRAN RETINA */
                            @media only screen and (-webkit-max-device-pixel-ratio: 1.5),
                            only screen and (-o-max-device-pixel-ratio: 3/2),
                            only screen and (max--moz-device-pixel-ratio: 1.5),
                            only screen and (max-device-pixel-ratio: 1.5) {
                            	background: url('../img/checked.png') center center no-repeat;
                            }
                            @media only screen and (-webkit-min-device-pixel-ratio: 1.5),
                            only screen and (-o-min-device-pixel-ratio: 3/2),
                            only screen and (min--moz-device-pixel-ratio: 1.5),
                            only screen and (min-device-pixel-ratio: 1.5) {
                                background: url('../img/checked@2x.png') center center no-repeat;
                                background-size: 8px 8px;
                            }
                        }
                    }
                }
            }
        }

        &-recap {

            h2 {
              font-size:1.2em;
              display: block;
              margin-bottom: 15px;
              border-bottom: 1px solid #e5e5e5;
              width:100%;
              padding-bottom:0.5em;
              text-transform: uppercase;
              text-align:left;
              color:$text-color;
              a{
                  display:block;
                  font-size:14px;
                  margin-top:10px;
                  color:$text-color;
                  &:hover,
                  &:focus{
                      color:$gold;
                  }
              }
            }
            .content {
                background: $bg-box-color;
                border: 1px solid $border-box-color;
                width: auto;

                p {
                    margin: 0;
                    margin-bottom: 5px;
                    strong {
                        color: $black;
                    }
                }
            }
            &.small-width {
                flex: 0 0 35%;

                .content {
                    display: block;
                    padding: 0;
                }

                .info {
                    border-bottom: 1px solid $border-box-color;
                    padding: 20px;
                }
                .recap-item {
                    padding: 20px 20px 0;

                    p.total {
                        padding: 20px;
                        border-top: 1px solid $border-box-color;
                        background: lighten($bg-box-color, 5%);
                        margin: 20px -20px 0;
                        text-align: center;
                        color: $black;
                    }

                    h4 {
                        font-size: 16px;
                    }
                }
            }
            &.full-width {
                flex: 0 0 100%;
                display: block;
                margin-bottom: 30px;

                .content {
                    display:block;
                    padding: 5%;

                    .btn {
                        display: inline-block;
                    }
                }
            }
        }
    }

    .recap-item {

        .item-person {
            margin-bottom: 30px;
        }

        ul.item-produit {

            li {
                padding: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.col-3 {
                  .name {
                    flex: 1;
                  }
                }

                &.col-4 {
                  .choices {
                    flex: 1;
                  }
                }
                .name {
                  /*white-space: nowrap;*/
                  margin-right: 20px;
                  font-weight: 600;
                }

                .price {
                    white-space: nowrap;
                }

      					.choices {
      					  font-weight: 400;
      					  font-size: 12px;
                  margin-right: 20px;
      					}

                .btn-remove {
                    margin-left:20px;
                }

                &:nth-child(2n+1){
                    background: lighten($grey, 17%);
                }
            }
        }
    }
}
form.options {
    h3 {
        margin-top: 30px;
        margin-bottom: 10px;
    }
}
#popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: rgba($black, 0.7);
    display: none;
    overflow: scroll;

    .container {
        width: 75%;
        max-width: 800px;
        position: absolute;
        left: 50%;
        background: $white;
        padding: 1em;
        transition: all linear .2s;
        opacity: 0;
        transform: translate(-50%, 0px);
        border-radius: 5px;
        box-shadow: 0px 0px 10px $black;
        margin-bottom: 100px;
        padding: 0;

        .container-fluid {

            h1 {
                margin-bottom: 0;
            }

            .container-fluid {
                padding: 5%;
            }
            .details {
                h3 {
                    margin-bottom: 20px;
                }
                h4 {
                    color: $gold;
                }
                p {
                    margin-bottom: 10px;

                    strong {
                        color: $gold;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    &.active {
        display: block;

        .container {
            opacity: 1;
            transform: translate(-50%, 100px);
        }
    }

    .row {
        display: flex;
        .preview {
            margin-right: 3%;

        }

    }

    .close-popup{
        position: absolute;
        font-size: 30px;
        color: $white;
        top: -50px;
        right: -50px;
    }
    .preview-pic {
        position: relative;
    }
    .controls {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        span {
            cursor: pointer;
            font-size: 30px;
            position: absolute;
            color: $white;
            text-shadow: 1px 1px 2px black;

            &.prev {
                left: 10px;
            }

            &.next {
                right: 10px;
            }
        }
    }
}
.step-summary {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .btn-primary {
        margin: 0;

        &.next {
          margin-left: auto;
        }
    }

    .actions {
      &.align-right {
        flex: 0 0 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    .btn-back {
        margin-top: 0;
    }
}

.btn-outline {

    &:hover,
    &:focus {
      color: white !important;
    }
}

form.options {
    display:block;
    width: 100%;

    label {
        display: inline-block;
        margin-right: 20px;

        &:before {
            display: inline-block;
            content:"";
            width: 8px;
            height: 8px;
            border:2px solid $white;
            margin-right: 10px;
            vertical-align: middle;
            border-radius: 8px;
            box-shadow: 0px 0px 0px 2px $black;
        }
    }
    input[type="radio"] {
        display: none;
        width: auto;
        vertical-align: middle;

        &:checked + label:before {
            background: $black;
        }
    }

    input[type="submit"] {
        border-radius: 0;
    }

    .actions {
        margin-top: 40px;
    }
}
.row {
    display: flex;
    justify-content: space-between;

    .col-md-5 {
        width: 40%;
    }
    .col-md-7 {
        width: 57%;
    }
}

.thumb-nav {
    display: flex;
    flex-wrap: wrap;
    margin-left: -3px;
    margin-right: -3px;

    li {
        flex: 0 0 20%;
        list-style: none;
        overflow: hidden;
        position: relative;
        background-size: cover;
        border: 3px solid $white;
        box-sizing: border-box;
        cursor: pointer;

        &:before {
            padding-top: 100%;
            content:"";
            display: block;
        }

        &.current {
            pointer-events: none;
            opacity: 0.6;
        }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.align-right {
    text-align: right;

    &.actions {
      padding: 40px 0;
      min-height: 40px;
    }
}
.text-center {
  text-align: center;
  margin: 0 auto;

  .btn {
    margin: 0 auto;
  }
}

.pill {
  padding: 5px;
  border-radius: 3px;
  &-free {
    background: #267a7a;
    color: $white;
  }
}

.bath_entries {
  fieldset {
    margin-bottom: 50px;
  }

  .row {
    flex-wrap: wrap;
  }

  .persons {
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
    padding: 15px 10px 0;
    box-sizing: border-box;
    margin-bottom: 4%;

    input {
      display: none;

      &:checked + label {
        &:before {
          background: $gold;
        }
      }
    }

    label {
      position: relative;
      padding-left: 20px;
      cursor: pointer;

      &:before {
        display: block;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        content:"";
        box-shadow: 0 0 0 2px $gold;
        border: 2px solid #f2f2f2;
        position: absolute;
        left: 0;
      }
    }
  }

  .items {
    margin-bottom: 20px;
  }

  .line-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 5px;

    &:first-child {
      margin-top: 15px;
    }

    .info {
      flex: 0 0 100%;
      margin-top: 5px;
      font-weight: bold;
    }
  }
}

@import 'responsive';

@import "reservation-bar";
