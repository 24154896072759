@media only screen and (max-width: 700px) {
	.navbar-header{
		text-align:center;
	}
	.navbar-brand{
		img{
			height: auto !important;
		}
	}
	.step {
    	display: none;
		justify-content: center;
		> li{
			padding-left:2%;
			width:auto;
			font-size:12px;
			height: 35px;
			color:lighten($text-color,30%);
			text-transform: none;
			&:first-child{
				padding-left:0;
			}
			&:before{
				display:none;
			}
			&.before{
				color:$text-color;
				background:none;
				a{
					color:$text-color;
				}
			}
			&.active{
				background:none;
				color:$gold;
			}
			span{
				position: relative;
				&.hidden{
					display:inline-block;
				}
				&:before,
				&:after{
					display:none;
				}
				i{
					display:inline-block;
				}
			}
		}
	}
	#app .content .table td.last a{
		font-size:0;
		span{
			font-size:14px;
		}
	}
	footer .reinsurance .row {
		flex-wrap: wrap;
		justify-content: space-between;
		div{
			width:70%;
			margin:0 auto 15px;
		}
	}
	nav.navbar .container{
		display:block;
		.navbar-right{
			justify-content:center;
		}
	}
	form select{
		width:94.5%;
	}
	fieldset{
		&.col-md-6{
			width:100%;
			margin:20px auto;
		}
	}

  .type-soin {
    display: flex;
    align-items: center;

    &:before {
      flex: 0 0 27px;
    }
  }

  #app {
    .item-list-element {
      display: block;
      padding: 0;
      margin: 40px 0;

      &:nth-child(2n+1) {
        background: none;
      }

      img {
        width: 100%;
      }

      .item-texte {
        margin: 0;
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        padding: 15px;
      }

      .col-3 {
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        border-bottom: 1px solid $grey;
        padding: 15px;

      }

      .item-checked,
      .item-to-checked {
        display: block;

        div {
          img {
            width: 50px;
          }
        }
      }
    }
    .content {
      display: block;

      .filters {
          display: block;

		  h3 {
			  display: flex;
			  align-items: center;
			  justify-content: space-between;

			  &:after {
				  font: normal normal normal 14px/1 FontAwesome;
				  font-size: inherit;
				  text-rendering: auto;
				  -webkit-font-smoothing: antialiased;
				  -moz-osx-font-smoothing: grayscale;
				  display: inline-block;
				  content: "\f078";
				  color: $black;
				  float: right;
			  }

			  &.active {
				  &:after {
					  content:"\f00d";
				  }
			  }
		  }

          form,
          form.first {
              display: block;

              fieldset,
              fieldset:nth-child(2) {
                  margin: 0;
                  margin-bottom: 20px;
              }

              select {
                  width: 100%;
              }
          }

          form.first {
	          display: none;
	          margin-top: 30px;

	          fieldset {
		          flex: 0 0 100%;
	          }
          }
      	}
    }
   .book {
     form {
       display: block;
       .header {
         display: block;
       }
       img {
         width: 100%;
         height: auto;
         margin: 0;
         margin-bottom: 15px;
       }

       .offers {
         display: block;
         line-height: 1.6;
       }

       .group-item {
         padding: 25px 0;
       }
      }
    }

    .book-recap {
      margin-top: 30px;
      // &.small-width {
      //     margin-top: 90px;
      // }
    }
  }

  #popup {
      .row {
          display: block;
          .col-md-6 {
              width: 100%;
              margin: 0;
          }
      }

      .close-popup {
          right: 0;
      }
  }

  .btn-back {
	  width: 100% !important;
	  box-sizing: border-box;
  }
  #app .book form input[type="submit"] {
	  width: 100% !important;
	  position: relative;
  }

  .row {
	  display: block;

	  .col-md-5 {
	  	width: 100%;
	  	margin-top: 20px;
	  }
  }

	form {
		.row {
			.col-md-3,
			.col-md-2,
		  .col-md-7 {
				margin-top: 20px;
			}
		}
	}

  .step-summary .btn-primary {
	  width: 100% !important;
	  margin-top: 20px;
	  box-sizing: border-box;
  }

	.step-summary {

    .btn-primary {

        &.next {
          margin-left: 0;
        }
    }

    .actions {
      &.align-right {
        display: block;
      }
    }
	}
}
