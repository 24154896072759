.alert{
	display:block;
	padding:1em;
	max-width:1170px;
	margin:30px auto;
	border-radius:3px;
	border:1px solid $white;
	box-sizing: border-box;
	width: 100%;

	&.alert-success{
		background:$green;
		border-color:darken($green,5%);
		color:darken($green, 35%);
	}
	&.alert-info{
		background:$blue;
		border-color:darken($blue,5%);
		color:darken($blue, 35%);
	}
	&.alert-warning{
		background:$yellow;
		border-color:darken($yellow,5%);
		color:darken($yellow, 60%);
	}
	&.alert-danger{
		background:$red;
		border-color:darken($red,5%);
		color:darken($red, 35%);
	}
	ul{
		padding-left:20px;
		li{
			line-height:1.4;
		}
	}
	i {
    	margin-right: 10px;
	}
}