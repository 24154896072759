.btn {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  width: auto !important;
  cursor: pointer;
  border: none;

  &-primary{
    background:$gold;
    text-decoration:none;
    border-radius: 3px;
    padding:1em 1.4em;
    color:$white;
    margin:20px 0.8em 40px 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $white !important;
    i{
        margin-left:7px;
    }
    &:hover,
    &:focus{
        background:darken($gold, 10%);
    }
}
  &-default {
    background: none;
    border:2px solid $black;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $black;

    &:hover,
    &:focus {
      background: $gold;
      border-color: $gold;
      color: $black !important;
    }
  }

  &-back {
    background: $grey;
    color: $black !important;
    margin-top: 30px;
    text-decoration: none;
    border: 2px solid $grey;
    font-size: 13px;

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: "\f053";
      color: $text-color;
      margin-right: 10px;
    }

    &:hover,
    &:focus {
      background:lighten($grey, 10%);
    }
  }

  &-next {
    background: $grey;
    color: $black !important;
    margin-top: 30px;

    &:after {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: inherit;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: "\f054";
      color: $text-color;
      margin-left: 10px;
    }

    &:hover,
    &:focus {
      background:lighten($grey, 10%);
    }
  }

  &-remove {
    background: none;
    color: $black !important;
    text-decoration: none;
    margin-top: 0;
    font-size: 0;
    padding-right: 0;
    padding-left: 0;

    &:before {
      font: normal normal normal 14px/1 FontAwesome;
      font-size: 18px;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: inline-block;
      content: "\f00d";
      color: $black;
      margin-right: 10px;
    }

    &:hover,
    &:focus {
        &:before {
          color: $gold;
        }
    }
  }

  &-secondary {
    background: $grey;
    color: $black !important;
    margin-top: 30px;
    white-space: nowrap;
    margin: 30px auto 0 !important;
    text-decoration: none;


    &:hover,
    &:focus {
      background:lighten($grey, 15%);
    }
  }
}
