$brand-color: #d2af59;


// Container
// -----------------------------------------------------------------------------

.datepicker-container {
  font-size: 12px;
  line-height: 30px;

  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;

  width: 210px;

  user-select: none;

  background-color: #fff;

  direction: ltr !important; // Ignore global rtl
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;

  &:before,
  &:after {
    position: absolute;

    display: block;

    width: 0;
    height: 0;

    content: ' ';

    border: 5px solid transparent;
  }
}

.datepicker-dropdown {
  position: absolute;
  z-index: 1;

  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;

  border: 1px solid #ccc;
  box-shadow: 0 3px 6px #ccc;
}

.datepicker-inline {
  position: static;
}

.datepicker-top-left,
.datepicker-top-right {

  &:before,
  &:after {
    top: -6px;
    left: 10px;

    border-top: 0;
  }

  &:before {
    border-bottom-color: #CCC;
  }

  &:after {
    top: -4px;

    border-bottom-color: #fff;
  }
}

.datepicker-bottom-left,
.datepicker-bottom-right {
  border-bottom-color: $brand-color;

  &:before,
  &:after {
    bottom: -5px;
    left: 10px;

    border-bottom: 0;
  }

  &:before {
    border-top-color: $brand-color;
  }

  &:after {
    bottom: -4px;

    border-top-color: #fff;
  }
}

.datepicker-top-right,
.datepicker-bottom-right {
  &:before,
  &:after {
    right: 10px;
    left: auto;
  }
}


// Panels
// -----------------------------------------------------------------------------

.datepicker-panel {
  > ul:before,
  > ul:after {
    display: table;

    content: ' ';
  }

  > ul:after {
    clear: both;
  }

  > ul {
    width: 102%;
    margin: 0;
    padding: 0;

    > li {
      float: left;

      width: 30px;
      height: 30px;
      margin: 0;
      padding: 0;

      list-style: none;

      cursor: pointer;
      text-align: center;

      background-color: #fff;

      &:hover {
        background-color: lighten($brand-color, 35%);
      }

      &.muted,
      &.muted:hover {
        color: #999;
      }

      &.highlighted {
        background-color: lighten($brand-color, 35%);
      }

      &.highlighted:hover {
        background-color: lighten($brand-color, 30%);
      }

      &.picked,
      &.picked:hover {
        color: $brand-color;
      }

      &.disabled,
      &.disabled:hover {
        cursor: default;

        color: #ccc;
        background-color: #fff;

        &.highlighted {
          background-color: lighten($brand-color, 35%);
        }
      }

      &[data-view='years prev'],
      &[data-view='year prev'],
      &[data-view='month prev'],
      &[data-view='years next'],
      &[data-view='year next'],
      &[data-view='month next'],
      &[data-view='next'] {
        font-size: 18px;
      }

      &[data-view='years current'],
      &[data-view='year current'],
      &[data-view='month current'] {
        width: 150px;
      }
    }

    &[data-view='years'],
    &[data-view='months'] {
      > li {
        line-height: 52.5px;

        width: 52.5px;
        height: 52.5px;
      }
    }

    &[data-view='week'] {
      > li,
      > li:hover {
        cursor: default;

        background-color: #fff;
      }
    }
  }
}


// Utilities
// -----------------------------------------------------------------------------

.datepicker-hide {
  display: none;
}
.reservation-bar {
    .btn-default {
        margin-top: 20px;
    }
}

.reservation-bar {
    background:rgba(0, 0, 0, 0.7);
    padding: 2%;
    display: block;
    width: 96%;
    color: #fff;
    bottom: 0;
    left: 0;
    right: 0;
    form {
        display: flex;
        max-width: 1160px;
        margin: 0 auto;
        width: 100%;
        flex-wrap: nowrap;
        align-items: flex-end;

        div {
            margin-right: 15px;
            &.form-group {

                &.little-group {
                    flex: 1 0 110px;
                }
                div {
                    margin-right: 0;
                }
            }
        }

        label {
            font-weight: 200;
        }
    }
    button {
        border: 2px solid #fff;
        text-transform: uppercase;
        color: #fff;
        letter-spacing: 1px;
        text-align: center;
        padding: 10px 15px;
        margin-top: 0;
        margin-bottom: 10px;
        white-space: nowrap;

        &:hover,
        &:focus {
            background: #d2af59;
            border-color: #d2af59;
            color: #000;
        }
    }
}


@media only screen and (max-width: 1160px) {
    .reservation-bar {
        form {
            flex-wrap: wrap;
            justify-content: flex;
            div {
                &.form-group {
                    flex: 1 0 160px;
                }
            }
            select {
                width: 100%;
            }
            button {
                margin-left: -8px;
            }

        }
    }
}