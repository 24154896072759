@charset "UTF-8";
@import url(https://fonts.googleapis.com/css?family=Montserrat:700,400,200|Cinzel:400|Raleway:400,600);
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  max-width: 100%;
}

.btn {
  display: inline-block;
  padding: 10px 15px;
  text-align: center;
  width: auto !important;
  cursor: pointer;
  border: none;
}

.btn-primary {
  background: #d2af59;
  text-decoration: none;
  border-radius: 3px;
  padding: 1em 1.4em;
  color: #fff;
  margin: 20px 0.8em 40px 0;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff !important;
}

.btn-primary i {
  margin-left: 7px;
}

.btn-primary:hover, .btn-primary:focus {
  background: #c39a35;
}

.btn-default {
  background: none;
  border: 2px solid #000;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #000;
}

.btn-default:hover, .btn-default:focus {
  background: #d2af59;
  border-color: #d2af59;
  color: #000 !important;
}

.btn-back {
  background: #cccccc;
  color: #000 !important;
  margin-top: 30px;
  text-decoration: none;
  border: 2px solid #cccccc;
  font-size: 13px;
}

.btn-back:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\f053";
  color: #777475;
  margin-right: 10px;
}

.btn-back:hover, .btn-back:focus {
  background: #e6e5e5;
}

.btn-next {
  background: #cccccc;
  color: #000 !important;
  margin-top: 30px;
}

.btn-next:after {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\f054";
  color: #777475;
  margin-left: 10px;
}

.btn-next:hover, .btn-next:focus {
  background: #e6e5e5;
}

.btn-remove {
  background: none;
  color: #000 !important;
  text-decoration: none;
  margin-top: 0;
  font-size: 0;
  padding-right: 0;
  padding-left: 0;
}

.btn-remove:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: 18px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  content: "\f00d";
  color: #000;
  margin-right: 10px;
}

.btn-remove:hover:before, .btn-remove:focus:before {
  color: #d2af59;
}

.btn-secondary {
  background: #cccccc;
  color: #000 !important;
  margin-top: 30px;
  white-space: nowrap;
  margin: 30px auto 0 !important;
  text-decoration: none;
}

.btn-secondary:hover, .btn-secondary:focus {
  background: #f2f2f2;
}

h1 {
  font-family: "Cinzel";
  font-size: 50px;
  color: #000;
}

h2 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 25px;
  color: #000;
}

h3 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 21px;
  color: #000;
}

h4 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 18px;
  color: #000;
}

h5 {
  font-family: "Cinzel";
  font-size: 18px;
  color: #000;
}

h6 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 16px;
  color: #000;
}

.alert {
  display: block;
  padding: 1em;
  max-width: 1170px;
  margin: 30px auto;
  border-radius: 3px;
  border: 1px solid #fff;
  box-sizing: border-box;
  width: 100%;
}

.alert.alert-success {
  background: #a1c8c1;
  border-color: #91bfb6;
  color: #43736b;
}

.alert.alert-info {
  background: #c4e1e8;
  border-color: #b2d8e1;
  color: #469eb4;
}

.alert.alert-warning {
  background: #ffe6ad;
  border-color: #ffde94;
  color: #7a5500;
}

.alert.alert-danger {
  background: #d9534f;
  border-color: #d43f3a;
  color: #611715;
}

.alert ul {
  padding-left: 20px;
}

.alert ul li {
  line-height: 1.4;
}

.alert i {
  margin-right: 10px;
}

*:focus {
  outline: none;
}

.navbar {
  background-color: #FFFFFF;
}

.navbar-nav > li > a {
  line-height: 52px;
}

.navbar-brand {
  height: 80px;
}

.navbar-brand img {
  margin-bottom: -20px;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 22px;
  list-style: none;
  background-color: inherit;
  border-radius: inherit;
}

.breadcrumb > li {
  color: #ccc;
}

.breadcrumb > li + li:before {
  content: "\003e ";
}

.breadcrumb > .active {
  color: inherit;
  font-weight: bold;
}

.payments li {
  display: inline-block;
}

.reinsurance .glyphicon {
  font-size: 2.5em;
}

.reinsurance.row {
  margin-top: 25px;
}

.payments.row {
  margin-top: 25px;
}

.terms.row {
  margin-top: 25px;
}

.su-accordion {
  margin-bottom: 1.5em;
}

.su-accordion .su-spoiler {
  margin-bottom: 0.5em;
}

.su-spoiler-title {
  position: relative;
  cursor: pointer;
  min-height: 20px;
  line-height: 20px;
  padding: 7px 7px 7px 34px;
  font-weight: bold;
  font-size: 13px;
}

.su-spoiler-icon {
  position: absolute;
  left: 7px;
  top: 7px;
  display: block;
  width: 20px;
  height: 20px;
  line-height: 21px;
  text-align: center;
  font-size: 14px;
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  *margin-right: .3em;
}

.su-spoiler-content {
  padding: 14px;
  -webkit-transition: padding-top .2s;
  -moz-transition: padding-top .2s;
  -o-transition: padding-top .2s;
  transition: padding-top .2s;
  -ie-transition: padding-top .2s;
}

.su-spoiler.su-spoiler-closed > .su-spoiler-content {
  height: 0;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: none;
  opacity: 0;
}

.su-spoiler-icon-plus .su-spoiler-icon:before {
  content: "\f068";
}

.su-spoiler-icon-plus.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f067";
}

.su-spoiler-icon-plus-circle .su-spoiler-icon:before {
  content: "\f056";
}

.su-spoiler-icon-plus-circle.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f055";
}

.su-spoiler-icon-plus-square-1 .su-spoiler-icon:before {
  content: "\f146";
}

.su-spoiler-icon-plus-square-1.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f0fe";
}

.su-spoiler-icon-plus-square-2 .su-spoiler-icon:before {
  content: "\f117";
}

.su-spoiler-icon-plus-square-2.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f116";
}

.su-spoiler-icon-arrow .su-spoiler-icon:before {
  content: "\f063";
}

.su-spoiler-icon-arrow.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f061";
}

.su-spoiler-icon-arrow-circle-1 .su-spoiler-icon:before {
  content: "\f0ab";
}

.su-spoiler-icon-arrow-circle-1.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f0a9";
}

.su-spoiler-icon-arrow-circle-2 .su-spoiler-icon:before {
  content: "\f01a";
}

.su-spoiler-icon-arrow-circle-2.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f18e";
}

.su-spoiler-icon-chevron .su-spoiler-icon:before {
  content: "\f078";
}

.su-spoiler-icon-chevron.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f054";
}

.su-spoiler-icon-chevron-circle .su-spoiler-icon:before {
  content: "\f13a";
}

.su-spoiler-icon-chevron-circle.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f138";
}

.su-spoiler-icon-caret .su-spoiler-icon:before {
  content: "\f0d7";
}

.su-spoiler-icon-caret.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f0da";
}

.su-spoiler-icon-caret-square .su-spoiler-icon:before {
  content: "\f150";
}

.su-spoiler-icon-caret-square.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f152";
}

.su-spoiler-icon-folder-1 .su-spoiler-icon:before {
  content: "\f07c";
}

.su-spoiler-icon-folder-1.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f07b";
}

.su-spoiler-icon-folder-2 .su-spoiler-icon:before {
  content: "\f115";
}

.su-spoiler-icon-folder-2.su-spoiler-closed .su-spoiler-icon:before {
  content: "\f114";
}

.su-spoiler-style-default > .su-spoiler-title {
  padding-left: 27px;
  padding-right: 0;
}

.su-spoiler-style-default > .su-spoiler-title > .su-spoiler-icon {
  left: 0;
}

.su-spoiler-style-default > .su-spoiler-content {
  padding: 1em 0 1em 27px;
}

.su-spoiler-style-fancy {
  border: 1px solid #ccc;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #fff;
  color: #333;
}

.su-spoiler-style-fancy > .su-spoiler-title {
  border-bottom: 1px solid #ccc;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #f0f0f0;
  font-size: 0.9em;
}

.su-spoiler-style-fancy.su-spoiler-closed > .su-spoiler-title {
  border: none;
}

.su-spoiler-style-fancy > .su-spoiler-content {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.su-spoiler-style-simple {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.su-spoiler-style-simple > .su-spoiler-title {
  padding: 5px 10px;
  background: #f0f0f0;
  color: #333;
  font-size: 0.9em;
}

.su-spoiler-style-simple > .su-spoiler-title > .su-spoiler-icon {
  display: none;
}

.su-spoiler-style-simple > .su-spoiler-content {
  padding: 1em 10px;
  background: #fff;
  color: #333;
}

/* Céline */
body {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: #777475;
}

body.fixed {
  overflow-y: hidden;
}

.fa-angle-right {
  display: inline-block;
  margin-left: 10px;
}

h1 {
  font-size: 2.4em;
}

h4 {
  font-size: 1.4em;
  margin-bottom: 0.4em;
}

a {
  transition: all linear .2s;
}

td i {
  display: block;
  color: #cccccc;
  font-style: italic;
  font-size: 12px;
  margin: 5px 0;
}

.pf {
  font-size: 50px;
  color: #d2af59;
}

.wrapper {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
}

.navbar-toggle {
  display: none;
}

fieldset {
  width: 100%;
}

button {
  cursor: pointer;
}

.col-md-2 {
  width: 13%;
}

.col-md-3 {
  width: 23%;
}

.col-md-4 {
  width: 30%;
}

@media only screen and (max-width: 820px) {
  .col-md-4.col-12 {
    width: 100%;
  }
}

.col-md-6 {
  width: 48.5%;
}

@media only screen and (max-width: 700px) {
  .col-md-6.col-12 {
    width: 100%;
    margin-bottom: 50px;
  }
}

.col-md-7 {
  width: 58%;
}

.col-md-8 {
  width: 67%;
}

@media only screen and (max-width: 820px) {
  .col-md-8.col-12 {
    width: 100%;
    margin-bottom: 50px;
  }
}

.breadcrumb {
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding: 0;
}

nav.navbar {
  margin-bottom: 0;
}

nav.navbar .container {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

nav.navbar .navbar-right {
  display: flex;
}

nav.navbar .navbar-right li {
  margin-left: 25px;
  list-style: none;
}

nav.navbar .navbar-right li .log {
  font-size: 0.9em;
}

nav.navbar .navbar-right .fa-shopping-basket {
  font-size: 1.3em;
}

nav.navbar .navbar-right a {
  color: #777475;
  text-decoration: none;
}

nav.navbar .navbar-right a:hover, nav.navbar .navbar-right a:focus {
  color: #d2af59;
}

.breadcrumb {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}

.step {
  display: flex;
  max-width: 1170px;
  width: 90%;
  background: #f2f2f2;
  counter-reset: counter;
  margin: 20px auto 0;
}

.step.text-center {
  margin-top: 20px;
}

.step > li {
  width: 25%;
  text-transform: uppercase;
  font-weight: normal;
  height: 50px;
  display: flex;
  align-items: center;
  position: relative;
}

.step > li:before {
  display: inline-block;
  content: counter(counter);
  counter-increment: counter;
  background: rgba(255, 255, 255, 0.6);
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 3px;
  margin-right: 10px;
  text-align: center;
  margin-left: 4%;
}

.step > li + li:before {
  content: counter(counter);
  counter-increment: counter;
}

.step > li a {
  display: block;
}

.step > li span {
  position: absolute;
  right: 0;
  top: 0;
}

.step > li span.hidden {
  display: none;
}

.step > li span i {
  display: none;
}

.step > li span:before {
  display: block;
  content: "";
  border-top: 25px solid #f2f2f2;
  border-left: 25px solid transparent;
}

.step > li span:after {
  display: block;
  content: "";
  border-bottom: 25px solid #f2f2f2;
  border-left: 25px solid transparent;
}

.step > li.active {
  background: #d2af59;
  color: #fff;
  font-weight: normal;
}

.step > li.active:before {
  background: rgba(255, 255, 255, 0.4);
  color: #fff;
}

.step > li.completed {
  background: #bb9433;
}

.step > li.completed:before {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.step > li.completed a {
  text-decoration: none;
  color: #fff;
  cursor: pointer;
}

.step > li.before {
  background: #bb9433;
}

.step > li.before span:before {
  border-top-color: #d2af59;
}

.step > li.before span:after {
  border-bottom-color: #d2af59;
}

#app {
  padding: 0;
}

h1 {
  background: url("../img/bg-title.jpg") center center no-repeat;
  background-size: cover;
  color: #fff;
  font-family: "Cinzel", serif;
  text-shadow: 1px 1px 2px black;
  text-align: center;
  padding: 1.2em 0;
  margin-bottom: 1.4em;
}

> .row {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.content {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: calc(2% + 10px) 0 2%;
  align-items: flex-start;
}

.content > a {
  text-decoration: none;
  margin-bottom: 50px;
  display: block;
  flex: none;
  width: 100%;
  color: #777475;
}

.content > a:hover, .content > a:focus {
  color: #d2af59;
}

.content > a span {
  font-size: 18px;
  display: inline-block;
  margin-right: 7px;
}

.content legend {
  font-size: 1.2em;
  display: block;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  text-align: left;
}

.content legend a {
  display: block;
  font-size: 14px;
  margin-top: 10px;
  color: #777475;
}

.content legend a:hover, .content legend a:focus {
  color: #d2af59;
}

.content p {
  margin: 1em 0;
}

.content p.label {
  margin: 0.8em 0;
}

.content p strong {
  color: #d2af59;
}

.content p.total {
  text-transform: uppercase;
  font-size: 16px;
  text-align: right;
  padding-right: 0.8em;
  font-weight: bold;
}

.content p.total span {
  color: #5d5b5c;
  color: #d2af59;
}

.content p.total span.vat-included {
  text-transform: none;
  font-size: 12px;
  display: block;
  margin-top: 5px;
}

.content p.tva {
  text-align: right;
  padding-right: 0.8em;
}

.content p a {
  color: #d2af59;
}

.content fieldset > a {
  float: right;
  margin-top: 30px;
  color: #fff;
  text-decoration: none;
  border-radius: 3px;
  display: inline-block;
  padding: 1em;
  background: #d2af59;
}

.content fieldset > a:hover, .content fieldset > a:focus {
  background: #cda545;
}

.content fieldset > a.edit {
  background: #f2f2f2;
  color: #777475;
  font-size: 80%;
}

.content fieldset > a.edit:hover, .content fieldset > a.edit:focus {
  background: #d2af59;
  color: #fff;
}

.content .table {
  width: 100%;
  max-width: 100%;
}

.content .table > thead > tr > th,
.content .table > thead > tr > td,
.content .table > tbody > tr > th,
.content .table > tbody > tr > td,
.content .table > tfoot > tr > th,
.content .table > tfoot > tr > td {
  vertical-align: top;
  border-top: 1px solid #ddd;
  padding: 1em 0.8em;
  text-align: left;
}

.content .table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  background: #f2f2f2;
}

.content .table > caption + thead > tr:first-child > th,
.content .table > caption + thead > tr:first-child > td,
.content .table > colgroup + thead > tr:first-child > th,
.content .table > colgroup + thead > tr:first-child > td,
.content .table > thead:first-child > tr:first-child > th,
.content .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.content .table > tbody + tbody {
  border-top: 2px solid #ddd;
}

.content .table td.last {
  text-align: right;
}

.content .table td.last a {
  color: #d9534f;
  text-decoration: none;
}

form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

form .has-error input,
form .has-error textarea {
  border-color: #d9534f;
}

form .has-error .help-block {
  margin-top: 5px;
  color: #d9534f;
  font-size: 80%;
}

form .form-group {
  position: relative;
}

form .form-group span.fa-exclamation {
  color: #d9534f;
  position: absolute;
  right: 10px;
  top: 35px;
}

form > .form-group {
  width: 48.5%;
  margin-bottom: 10px;
}

form > .form-group.last {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

form > .form-group.last a {
  color: #777475;
}

form > .form-group.last a:hover, form > .form-group.last a:focus {
  color: #d2af59;
}

form > .form-group.last button {
  margin: 0;
  border: none;
  font-size: 100%;
  cursor: pointer;
}

form > .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

form select {
  background: #f2f2f2 url("../img/arrow-select.png") right center no-repeat;
  border: none;
  border: 1px solid #e6e5e5;
  width: 100%;
  display: block;
  font-size: 100%;
  color: #777475;
  height: 37px;
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-left: 10px;
}

form select::-ms-expand {
  display: none;
}

form label {
  display: block;
  padding: 5px 0;
}

form textarea {
  width: 100%;
  background: #f2f2f2;
  border: none;
  border: 1px solid #e6e5e5;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  height: 100px;
  border-radius: 4px;
}

form input {
  background: #f2f2f2;
  border: none;
  border: 1px solid #e6e5e5;
  padding: 1em;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
}

form .radio input,
form .checkbox input {
  display: inline-block;
  width: auto;
}

form .radio i,
form .checkbox i {
  font-style: italic;
  color: #aaa7a8;
}

form .checkbox {
  border-top: 1px solid #f2f2f2;
  margin-top: 20px;
  padding-top: 20px;
  width: 100%;
}

form .checkbox a {
  color: #d2af59;
}

form .checkbox.deliverycheck {
  background: #f2f2f2;
  padding: 0.8em;
  border: none;
  margin-bottom: 15px;
}

form button {
  border: none;
  font-size: 100%;
  margin: 0;
  cursor: pointer;
}

form button.pull-right {
  margin: 0;
  margin-top: 30px;
  align-self: flex-end;
  cursor: pointer;
}

form button:hover, form button:focus {
  background: #cda545;
}

form .next-step {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

form .next-step i {
  display: block;
  font-style: italic;
  color: #bfbfbf;
  margin-top: 10px;
}

#deliveryAddress {
  width: 100%;
  margin-top: 15px;
  margin-left: 5%;
}

#deliveryAddress > .row {
  display: flex;
  justify-content: space-between;
}

#subdelivery > .row {
  display: flex;
  justify-content: space-between;
}

footer {
  background: #e6e5e5;
}

footer .reinsurance {
  max-width: 1170px;
  width: 90%;
  margin: 0 auto;
  padding: 1.8em 0;
  color: #777475;
}

footer .reinsurance .row {
  display: flex;
  justify-content: space-between;
}

footer .reinsurance .row div {
  text-align: center;
}

footer .reinsurance .row div .fa {
  font-size: 3em;
  display: inline-block;
  margin-bottom: 0.3em;
}

footer .payments {
  background: #f2f2f2;
  margin-top: 0;
  padding: 1.6em;
}

footer .payments ul {
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

footer .payments ul li {
  margin: 0 10px;
}

footer .payments ul li img {
  margin: 0 5px;
}

footer .address.row {
  text-align: center;
  display: block;
  padding: 20px 0 0;
  font-size: 12px;
}

footer .terms.row {
  text-align: center;
  margin: 0;
  padding: 5px 0 20px;
  display: block;
}

footer .terms.row a {
  font-size: 11px;
  text-decoration: none;
  color: #777475;
  cursor: pointer;
  transition: all linear .2s;
}

footer .terms.row a:hover, footer .terms.row a:focus {
  color: #d2af59;
}

.wrapper h2 {
  font-family: "Cinzel", serif;
  font-size: 1.7em;
}

.wrapper .product-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.wrapper .product-list .item {
  width: 30%;
}

.wrapper .product-list .item:nth-child(3n+2) {
  margin: 5%;
}

.login {
  max-width: 700px;
  margin: 0 auto;
}

.login .next-step {
  justify-content: space-between;
}

.login .next-step button {
  margin: 0;
  cursor: pointer;
}

.login .next-step .link {
  display: flex;
  align-items: center;
}

.login .next-step .link a {
  margin: 0 10px;
  color: #777475;
}

.login .next-step .link a:hover, .login .next-step .link a:focus {
  color: #d2af59;
}

.pull-left {
  margin-top: 30px;
}

#app .content .filters {
  background: #eee;
  flex: 1;
  padding: 15px 20px;
  display: flex;
  color: #000;
  border: 1px solid #dedede;
}

#app .content .filters h3 {
  text-transform: uppercase;
  display: none;
  cursor: pointer;
  padding: 15px;
  margin-top: -15px;
  margin-left: -15px;
  margin-bottom: -15px;
  margin-right: -15px;
}

#app .content .filters.fixed {
  position: fixed;
  top: 0;
  max-width: 1108px;
  left: 5%;
  right: 5%;
}

@media only screen and (min-width: 1300px) {
  #app .content .filters.fixed {
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    width: 1108px;
  }
}

#app .content .filters form select {
  background: #fff url(../img/arrow-select.png) right center no-repeat;
}

#app .content .filters label {
  display: inline-block;
}

#app .content .filters label:before {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  margin-right: 10px;
  vertical-align: middle;
}

#app .content .filters input[type="submit"] {
  margin: 0;
  transition: all linear .2s;
  border-radius: 0;
  margin-top: 30px;
  cursor: pointer;
}

#app .content .filters input[type="checkbox"] {
  display: none;
  width: auto;
  vertical-align: middle;
}

#app .content .filters input[type="checkbox"]:checked + label:before {
  background: url("../img/checked.png") center center no-repeat;
  /* ECRAN RETINA */
}

@media only screen and (-webkit-max-device-pixel-ratio: 1.5), only screen and (-o-max-device-pixel-ratio: 3 / 2), only screen and (max--moz-device-pixel-ratio: 1.5), only screen and (max-device-pixel-ratio: 1.5) {
  #app .content .filters input[type="checkbox"]:checked + label:before {
    background: url("../img/checked.png") center center no-repeat;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #app .content .filters input[type="checkbox"]:checked + label:before {
    background: url("../img/checked@2x.png") center center no-repeat;
    background-size: 8px 8px;
  }
}

#app .content .filters form {
  flex: 0 0 30%;
}

#app .content .filters form.first {
  display: flex;
  flex: 0 0 100%;
  margin-right: 2%;
}

#app .content .filters form.first fieldset {
  flex: 0 0 32%;
}

#app .content .filters form.first fieldset:nth-child(2) {
  margin: 0 2%;
}

#app .content .filters legend {
  padding-bottom: 0;
  border: none;
}

#app .item-list {
  width: 100%;
}

#app .item-list p.results {
  font-weight: 300;
}

#app .item-list p.results span {
  color: #d2af59;
  font-weight: 700;
}

#app .item-list-element {
  padding: 5%;
  display: flex;
  align-items: flex-start;
}

#app .item-list-element:nth-child(2n+1) {
  background: #f2f2f2;
}

#app .item-list-element .item-texte {
  flex: 1;
  margin: 0 30px;
}

#app .item-list-element .pill {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  margin-top: 10px;
}

#app .item-list-element .pill-forfait {
  background: #9295ca;
}

#app .item-list-element .pill-soin {
  background: #9eceb6;
}

#app .item-list-element .col-3 {
  flex: 0 0 220px;
  text-align: center;
}

#app .item-list-element .col-3 .btn {
  width: 100%;
  box-sizing: border-box;
  margin-top: 20px;
}

#app .item-list-element .col-3 div p {
  color: #d2af59;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

#app .item-list-element .item-checked,
#app .item-list-element .item-to-checked {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#app .item-list-element .item-checked h4,
#app .item-list-element .item-to-checked h4 {
  flex: 0 0 100%;
  margin-bottom: 15px;
}

#app .item-list-element .item-checked div,
#app .item-list-element .item-to-checked div {
  line-height: 1.4;
  margin-bottom: 5px;
  flex: 0 0 49%;
  /*

            &:before {
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            display: inline-block;
            content: "\f00c";
            color: $gold;
            }
            */
}

#app .item-list-element .item-checked div a,
#app .item-list-element .item-to-checked div a {
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #777475;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
}

#app .item-list-element .item-checked div a:hover, #app .item-list-element .item-checked div a:focus,
#app .item-list-element .item-to-checked div a:hover,
#app .item-list-element .item-to-checked div a:focus {
  background: rgba(0, 0, 0, 0.05);
}

#app .item-list-element .item-checked div a img,
#app .item-list-element .item-to-checked div a img {
  margin-right: 15px;
}

#app .book {
  flex: 0 0 65%;
  align-items: flex-start;
}

#app .book h2 {
  margin-bottom: 0;
  font-family: "Cinzel", serif;
  font-weight: 400;
  font-size: 18px;
}

#app .book .alert {
  margin-bottom: 0;
}

#app .book form {
  display: block;
  position: relative;
}

#app .book form .header {
  display: flex;
  padding: 30px;
  border: 1px solid #dedede;
}

#app .book form .header h3 {
  margin-bottom: 10px;
}

#app .book form img {
  margin-right: 34px;
}

#app .book form .item-texte {
  flex: 1;
  border: 1px solid #dedede;
  border-top: none;
  padding: 30px;
  background: #fafafa;
}

#app .book form .offers {
  margin-bottom: 30px;
}

#app .book form .offers .price {
  color: #d2af59;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  white-space: nowrap;
  flex: 1;
}

#app .book form input {
  width: auto;
}

#app .book form input[type="submit"] {
  border-radius: 0;
  margin-top: 30px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

#app .book form .group-item {
  margin-bottom: 20px;
}

#app .book form .group-item select {
  margin-bottom: 15px;
}

#app .book form .group-item .person {
  margin-bottom: 15px;
}

#app .book form .group-item .person label {
  display: block;
  margin-right: 20px;
}

#app .book form .group-item .person label:before {
  display: inline-block;
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #000;
  margin-right: 10px;
  vertical-align: middle;
}

#app .book form .group-item .person label input {
  width: 80%;
  background: none;
  border: none;
  border-bottom: 2px solid #000;
  border-radius: 0;
  font-size: 15px;
  padding: 7px;
}

#app .book form .group-item .person input[type="checkbox"] {
  display: none;
  width: auto;
  vertical-align: middle;
}

#app .book form .group-item .person input[type="checkbox"]:checked + label:before {
  background: url("../img/checked.png") center center no-repeat;
  /* ECRAN RETINA */
}

@media only screen and (-webkit-max-device-pixel-ratio: 1.5), only screen and (-o-max-device-pixel-ratio: 3 / 2), only screen and (max--moz-device-pixel-ratio: 1.5), only screen and (max-device-pixel-ratio: 1.5) {
  #app .book form .group-item .person input[type="checkbox"]:checked + label:before {
    background: url("../img/checked.png") center center no-repeat;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (-o-min-device-pixel-ratio: 3 / 2), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #app .book form .group-item .person input[type="checkbox"]:checked + label:before {
    background: url("../img/checked@2x.png") center center no-repeat;
    background-size: 8px 8px;
  }
}

#app .book-recap h2 {
  font-size: 1.2em;
  display: block;
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  text-align: left;
  color: #777475;
}

#app .book-recap h2 a {
  display: block;
  font-size: 14px;
  margin-top: 10px;
  color: #777475;
}

#app .book-recap h2 a:hover, #app .book-recap h2 a:focus {
  color: #d2af59;
}

#app .book-recap .content {
  background: #eee;
  border: 1px solid #dedede;
  width: auto;
}

#app .book-recap .content p {
  margin: 0;
  margin-bottom: 5px;
}

#app .book-recap .content p strong {
  color: #000;
}

#app .book-recap.small-width {
  flex: 0 0 35%;
}

#app .book-recap.small-width .content {
  display: block;
  padding: 0;
}

#app .book-recap.small-width .info {
  border-bottom: 1px solid #dedede;
  padding: 20px;
}

#app .book-recap.small-width .recap-item {
  padding: 20px 20px 0;
}

#app .book-recap.small-width .recap-item p.total {
  padding: 20px;
  border-top: 1px solid #dedede;
  background: #fbfbfb;
  margin: 20px -20px 0;
  text-align: center;
  color: #000;
}

#app .book-recap.small-width .recap-item h4 {
  font-size: 16px;
}

#app .book-recap.full-width {
  flex: 0 0 100%;
  display: block;
  margin-bottom: 30px;
}

#app .book-recap.full-width .content {
  display: block;
  padding: 5%;
}

#app .book-recap.full-width .content .btn {
  display: inline-block;
}

#app .recap-item .item-person {
  margin-bottom: 30px;
}

#app .recap-item ul.item-produit li {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#app .recap-item ul.item-produit li.col-3 .name {
  flex: 1;
}

#app .recap-item ul.item-produit li.col-4 .choices {
  flex: 1;
}

#app .recap-item ul.item-produit li .name {
  /*white-space: nowrap;*/
  margin-right: 20px;
  font-weight: 600;
}

#app .recap-item ul.item-produit li .price {
  white-space: nowrap;
}

#app .recap-item ul.item-produit li .choices {
  font-weight: 400;
  font-size: 12px;
  margin-right: 20px;
}

#app .recap-item ul.item-produit li .btn-remove {
  margin-left: 20px;
}

#app .recap-item ul.item-produit li:nth-child(2n+1) {
  background: #f7f7f7;
}

form.options h3 {
  margin-top: 30px;
  margin-bottom: 10px;
}

#popup {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: none;
  overflow: scroll;
}

#popup .container {
  width: 75%;
  max-width: 800px;
  position: absolute;
  left: 50%;
  background: #fff;
  padding: 1em;
  transition: all linear .2s;
  opacity: 0;
  transform: translate(-50%, 0px);
  border-radius: 5px;
  box-shadow: 0px 0px 10px #000;
  margin-bottom: 100px;
  padding: 0;
}

#popup .container .container-fluid h1 {
  margin-bottom: 0;
}

#popup .container .container-fluid .container-fluid {
  padding: 5%;
}

#popup .container .container-fluid .details h3 {
  margin-bottom: 20px;
}

#popup .container .container-fluid .details h4 {
  color: #d2af59;
}

#popup .container .container-fluid .details p {
  margin-bottom: 10px;
}

#popup .container .container-fluid .details p strong {
  color: #d2af59;
  font-weight: 700;
}

#popup.active {
  display: block;
}

#popup.active .container {
  opacity: 1;
  transform: translate(-50%, 100px);
}

#popup .row {
  display: flex;
}

#popup .row .preview {
  margin-right: 3%;
}

#popup .close-popup {
  position: absolute;
  font-size: 30px;
  color: #fff;
  top: -50px;
  right: -50px;
}

#popup .preview-pic {
  position: relative;
}

#popup .controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}

#popup .controls span {
  cursor: pointer;
  font-size: 30px;
  position: absolute;
  color: #fff;
  text-shadow: 1px 1px 2px black;
}

#popup .controls span.prev {
  left: 10px;
}

#popup .controls span.next {
  right: 10px;
}

.step-summary {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.step-summary .btn-primary {
  margin: 0;
}

.step-summary .btn-primary.next {
  margin-left: auto;
}

.step-summary .actions.align-right {
  flex: 0 0 100%;
  display: flex;
  justify-content: space-between;
}

.step-summary .btn-back {
  margin-top: 0;
}

.btn-outline:hover, .btn-outline:focus {
  color: white !important;
}

form.options {
  display: block;
  width: 100%;
}

form.options label {
  display: inline-block;
  margin-right: 20px;
}

form.options label:before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  margin-right: 10px;
  vertical-align: middle;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 2px #000;
}

form.options input[type="radio"] {
  display: none;
  width: auto;
  vertical-align: middle;
}

form.options input[type="radio"]:checked + label:before {
  background: #000;
}

form.options input[type="submit"] {
  border-radius: 0;
}

form.options .actions {
  margin-top: 40px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.row .col-md-5 {
  width: 40%;
}

.row .col-md-7  {
  width: 57%;
}

.thumb-nav {
  display: flex;
  flex-wrap: wrap;
  margin-left: -3px;
  margin-right: -3px;
}

.thumb-nav li {
  flex: 0 0 20%;
  list-style: none;
  overflow: hidden;
  position: relative;
  background-size: cover;
  border: 3px solid #fff;
  box-sizing: border-box;
  cursor: pointer;
}

.thumb-nav li:before {
  padding-top: 100%;
  content: "";
  display: block;
}

.thumb-nav li.current {
  pointer-events: none;
  opacity: 0.6;
}

.thumb-nav li img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.align-right {
  text-align: right;
}

.align-right.actions {
  padding: 40px 0;
  min-height: 40px;
}

.text-center {
  text-align: center;
  margin: 0 auto;
}

.text-center .btn {
  margin: 0 auto;
}

.pill {
  padding: 5px;
  border-radius: 3px;
}

.pill-free {
  background: #267a7a;
  color: #fff;
}

.bath_entries fieldset {
  margin-bottom: 50px;
}

.bath_entries .row {
  flex-wrap: wrap;
}

.bath_entries .persons {
  background: #f2f2f2;
  border: 1px solid #e6e6e6;
  padding: 15px 10px 0;
  box-sizing: border-box;
  margin-bottom: 4%;
}

.bath_entries .persons input {
  display: none;
}

.bath_entries .persons input:checked + label:before {
  background: #d2af59;
}

.bath_entries .persons label {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}

.bath_entries .persons label:before {
  display: block;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: "";
  box-shadow: 0 0 0 2px #d2af59;
  border: 2px solid #f2f2f2;
  position: absolute;
  left: 0;
}

.bath_entries .items {
  margin-bottom: 20px;
}

.bath_entries .line-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
}

.bath_entries .line-item:first-child {
  margin-top: 15px;
}

.bath_entries .line-item .info {
  flex: 0 0 100%;
  margin-top: 5px;
  font-weight: bold;
}

@media only screen and (max-width: 700px) {
  .navbar-header {
    text-align: center;
  }
  .navbar-brand img {
    height: auto !important;
  }
  .step {
    display: none;
    justify-content: center;
  }
  .step > li {
    padding-left: 2%;
    width: auto;
    font-size: 12px;
    height: 35px;
    color: #c3c1c2;
    text-transform: none;
  }
  .step > li:first-child {
    padding-left: 0;
  }
  .step > li:before {
    display: none;
  }
  .step > li.before {
    color: #777475;
    background: none;
  }
  .step > li.before a {
    color: #777475;
  }
  .step > li.active {
    background: none;
    color: #d2af59;
  }
  .step > li span {
    position: relative;
  }
  .step > li span.hidden {
    display: inline-block;
  }
  .step > li span:before, .step > li span:after {
    display: none;
  }
  .step > li span i {
    display: inline-block;
  }
  #app .content .table td.last a {
    font-size: 0;
  }
  #app .content .table td.last a span {
    font-size: 14px;
  }
  footer .reinsurance .row {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  footer .reinsurance .row div {
    width: 70%;
    margin: 0 auto 15px;
  }
  nav.navbar .container {
    display: block;
  }
  nav.navbar .container .navbar-right {
    justify-content: center;
  }
  form select {
    width: 94.5%;
  }
  fieldset.col-md-6 {
    width: 100%;
    margin: 20px auto;
  }
  .type-soin {
    display: flex;
    align-items: center;
  }
  .type-soin:before {
    flex: 0 0 27px;
  }
  #app .item-list-element {
    display: block;
    padding: 0;
    margin: 40px 0;
  }
  #app .item-list-element:nth-child(2n+1) {
    background: none;
  }
  #app .item-list-element img {
    width: 100%;
  }
  #app .item-list-element .item-texte {
    margin: 0;
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    padding: 15px;
  }
  #app .item-list-element .col-3 {
    border-left: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    padding: 15px;
  }
  #app .item-list-element .item-checked,
  #app .item-list-element .item-to-checked {
    display: block;
  }
  #app .item-list-element .item-checked div img,
  #app .item-list-element .item-to-checked div img {
    width: 50px;
  }
  #app .content {
    display: block;
  }
  #app .content .filters {
    display: block;
  }
  #app .content .filters h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #app .content .filters h3:after {
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    content: "\f078";
    color: #000;
    float: right;
  }
  #app .content .filters h3.active:after {
    content: "\f00d";
  }
  #app .content .filters form,
  #app .content .filters form.first {
    display: block;
  }
  #app .content .filters form fieldset,
  #app .content .filters form fieldset:nth-child(2),
  #app .content .filters form.first fieldset,
  #app .content .filters form.first fieldset:nth-child(2) {
    margin: 0;
    margin-bottom: 20px;
  }
  #app .content .filters form select,
  #app .content .filters form.first select {
    width: 100%;
  }
  #app .content .filters form.first {
    display: none;
    margin-top: 30px;
  }
  #app .content .filters form.first fieldset {
    flex: 0 0 100%;
  }
  #app .book form {
    display: block;
  }
  #app .book form .header {
    display: block;
  }
  #app .book form img {
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 15px;
  }
  #app .book form .offers {
    display: block;
    line-height: 1.6;
  }
  #app .book form .group-item {
    padding: 25px 0;
  }
  #app .book-recap {
    margin-top: 30px;
  }
  #popup .row {
    display: block;
  }
  #popup .row .col-md-6 {
    width: 100%;
    margin: 0;
  }
  #popup .close-popup {
    right: 0;
  }
  .btn-back {
    width: 100% !important;
    box-sizing: border-box;
  }
  #app .book form input[type="submit"] {
    width: 100% !important;
    position: relative;
  }
  .row {
    display: block;
  }
  .row .col-md-5 {
    width: 100%;
    margin-top: 20px;
  }
  form .row .col-md-3,
  form .row .col-md-2,
  form .row .col-md-7 {
    margin-top: 20px;
  }
  .step-summary .btn-primary {
    width: 100% !important;
    margin-top: 20px;
    box-sizing: border-box;
  }
  .step-summary .btn-primary.next {
    margin-left: 0;
  }
  .step-summary .actions.align-right {
    display: block;
  }
}

.datepicker-container {
  font-size: 12px;
  line-height: 30px;
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 210px;
  user-select: none;
  background-color: #fff;
  direction: ltr !important;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.datepicker-container:before, .datepicker-container:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  content: ' ';
  border: 5px solid transparent;
}

.datepicker-dropdown {
  position: absolute;
  z-index: 1;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px #ccc;
}

.datepicker-inline {
  position: static;
}

.datepicker-top-left:before, .datepicker-top-left:after,
.datepicker-top-right:before,
.datepicker-top-right:after {
  top: -6px;
  left: 10px;
  border-top: 0;
}

.datepicker-top-left:before,
.datepicker-top-right:before {
  border-bottom-color: #CCC;
}

.datepicker-top-left:after,
.datepicker-top-right:after {
  top: -4px;
  border-bottom-color: #fff;
}

.datepicker-bottom-left,
.datepicker-bottom-right {
  border-bottom-color: #d2af59;
}

.datepicker-bottom-left:before, .datepicker-bottom-left:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
  bottom: -5px;
  left: 10px;
  border-bottom: 0;
}

.datepicker-bottom-left:before,
.datepicker-bottom-right:before {
  border-top-color: #d2af59;
}

.datepicker-bottom-left:after,
.datepicker-bottom-right:after {
  bottom: -4px;
  border-top-color: #fff;
}

.datepicker-top-right:before, .datepicker-top-right:after,
.datepicker-bottom-right:before,
.datepicker-bottom-right:after {
  right: 10px;
  left: auto;
}

.datepicker-panel > ul:before,
.datepicker-panel > ul:after {
  display: table;
  content: ' ';
}

.datepicker-panel > ul:after {
  clear: both;
}

.datepicker-panel > ul {
  width: 102%;
  margin: 0;
  padding: 0;
}

.datepicker-panel > ul > li {
  float: left;
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  text-align: center;
  background-color: #fff;
}

.datepicker-panel > ul > li:hover {
  background-color: #f8f3e5;
}

.datepicker-panel > ul > li.muted, .datepicker-panel > ul > li.muted:hover {
  color: #999;
}

.datepicker-panel > ul > li.highlighted {
  background-color: #f8f3e5;
}

.datepicker-panel > ul > li.highlighted:hover {
  background-color: #f3e9d1;
}

.datepicker-panel > ul > li.picked, .datepicker-panel > ul > li.picked:hover {
  color: #d2af59;
}

.datepicker-panel > ul > li.disabled, .datepicker-panel > ul > li.disabled:hover {
  cursor: default;
  color: #ccc;
  background-color: #fff;
}

.datepicker-panel > ul > li.disabled.highlighted, .datepicker-panel > ul > li.disabled:hover.highlighted {
  background-color: #f8f3e5;
}

.datepicker-panel > ul > li[data-view='years prev'], .datepicker-panel > ul > li[data-view='year prev'], .datepicker-panel > ul > li[data-view='month prev'], .datepicker-panel > ul > li[data-view='years next'], .datepicker-panel > ul > li[data-view='year next'], .datepicker-panel > ul > li[data-view='month next'], .datepicker-panel > ul > li[data-view='next'] {
  font-size: 18px;
}

.datepicker-panel > ul > li[data-view='years current'], .datepicker-panel > ul > li[data-view='year current'], .datepicker-panel > ul > li[data-view='month current'] {
  width: 150px;
}

.datepicker-panel > ul[data-view='years'] > li, .datepicker-panel > ul[data-view='months'] > li {
  line-height: 52.5px;
  width: 52.5px;
  height: 52.5px;
}

.datepicker-panel > ul[data-view='week'] > li,
.datepicker-panel > ul[data-view='week'] > li:hover {
  cursor: default;
  background-color: #fff;
}

.datepicker-hide {
  display: none;
}

.reservation-bar .btn-default {
  margin-top: 20px;
}

.reservation-bar {
  background: rgba(0, 0, 0, 0.7);
  padding: 2%;
  display: block;
  width: 96%;
  color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
}

.reservation-bar form {
  display: flex;
  max-width: 1160px;
  margin: 0 auto;
  width: 100%;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.reservation-bar form div {
  margin-right: 15px;
}

.reservation-bar form div.form-group.little-group {
  flex: 1 0 110px;
}

.reservation-bar form div.form-group div {
  margin-right: 0;
}

.reservation-bar form label {
  font-weight: 200;
}

.reservation-bar button {
  border: 2px solid #fff;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
  text-align: center;
  padding: 10px 15px;
  margin-top: 0;
  margin-bottom: 10px;
  white-space: nowrap;
}

.reservation-bar button:hover, .reservation-bar button:focus {
  background: #d2af59;
  border-color: #d2af59;
  color: #000;
}

@media only screen and (max-width: 1160px) {
  .reservation-bar form {
    flex-wrap: wrap;
    justify-content: flex;
  }
  .reservation-bar form div.form-group {
    flex: 1 0 160px;
  }
  .reservation-bar form select {
    width: 100%;
  }
  .reservation-bar form button  {
    margin-left: -8px;
  }
}
