h1 {
  font-family: "Cinzel";
  font-size: 50px;
  color: $black;
}

h2 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 25px;
  color: $black;
}

h3 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 21px;
  color: $black;
}

h4 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 18px;
  color: $black;
}

h5 {
  font-family: "Cinzel";
  font-size: 18px;
  color: $black;
}

h6 {
  font-family: "Montserrat","Open Sans","Arial",sans-serif;
  font-size: 16px;
  color: $black;
}