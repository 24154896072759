
// Body
$body-bg: #ffffff;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$font-family-sans-serif: 'Montserrat', sans-serif; //, "Open Sans", Arial, sans-serif;
$font-family-serif: 'Cinzel', serif;
$font-size-base: 14px;
$line-height-base: 1.6;
$text-color: #777475;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;

$link-hover-color: #d2af59;


//Color
$white: #fff;
$grey: #cccccc;
$gold: #d2af59;
$red: #d9534f;
$green: #a1c8c1;
$blue: #c4e1e8;
$yellow: #ffe6ad;
$black: #000;

$border-box-color: #dedede;
$bg-box-color: #eee;

$label-blue: #9295ca;
$label-green: #9eceb6;